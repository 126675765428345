import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import {Card,CardHeader,CardMedia,CardContent,CardActions,IconButton,Collapse} from '@material-ui/core';

import FavoriteIcon from '@material-ui/icons/Favorite';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShareIcon from '@material-ui/icons/Share';



const styles = (theme) => ({
  root: {
    padding:"40px 0"
  },
  header: {
    marginBottom: "30px",
  },
  Card:{
    /*border:"1px solid #888"*/
  },
  product: {
    padding: "10px",
    textAlign: "center;",
  },
  chipsBox: {
    width:"100%",
    textAlign: "center;",
    margin:"10px 0 ",
  },
  chip:{
    margin:"0 3px",
  },
  ButtonBox: {
    width:"100%",
    textAlign: "center;",
    margin:"10px 0 "
  },
  Button:{
    marginTop:"10px",
    background:"#415A77",
    color:"#E0E1DD",
    fontSize:"16px",
    padding:"10px 20px",
    textDecoration: "none",
    "&:hover":{
      color:"#415A77",
      background:"#E0E1DD",

    }
  },
  ButtonLink:{
    textDecoration: "none"
  }
});

function ProductCategories(props) {
  const { classes } = props;

  return (
    <div className={classes.root} component="section">

      <Grid container spacing={3}>
        <Grid item xs="12" md={6} lg={4}>
          <Card className={classes.Card}>
            <CardHeader  title="Jected"  subheader="Under Construction"/>
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                Project and product management system to increase efficiency in your workflow.
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <a href="http://jected.com/" style={{textDecoration:"none"}} target="_blank"> 
                <Button className={classes.Button} variant="contained">
                  See More
                </Button>
              </a>
              {/*<IconButton aria-label="share">
                <ShareIcon />
  </IconButton>*/}
            </CardActions>
          </Card>
        </Grid>



<Grid item xs="12" md={6} lg={4}>
  <Card className={classes.Card}>
    <CardHeader  title="Eval"  subheader="Under Construction"/>
    <CardContent>
      <Typography variant="body2" color="textSecondary" component="p">
      Know what your buying, try before you buy.
      </Typography>
    </CardContent>
    <CardActions disableSpacing>
      <a href="http://eval.spried.com/" style={{textDecoration:"none"}} target="_blank"> 
        <Button className={classes.Button}  variant="contained">
          See More
        </Button>
      </a>
      {/*<IconButton aria-label="share">
                <ShareIcon />
  </IconButton>*/}
    </CardActions>
  </Card>
</Grid>




<Grid item xs="12" md={6} lg={4}>
          <Card className={classes.Card}>
            <CardHeader  title="Fix My Stuff" subheader="Under Construction" />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
              get others to help fix you broken items.
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <a href="http://fixmystuff.info/" style={{textDecoration:"none"}} target="_blank"> 
                <Button className={classes.Button} variant="contained">
                  See More
                </Button>
              </a>
              {/*<IconButton aria-label="share">
                <ShareIcon />
  </IconButton>*/}
            </CardActions>
          </Card>
        </Grid>





<Grid item xs="12" md={6} lg={4}>
  <Card className={classes.Card}>
    <CardHeader  title="Climate Sens" subheader="Under Construction" />
    <CardContent>
      <Typography variant="body2" color="textSecondary" component="p">
        Climate data gathering tool
      </Typography>
    </CardContent>
    <CardActions disableSpacing>
      <a href="http://climatesens.com/" style={{textDecoration:"none"}} target="_blank">
        <Button className={classes.Button} variant="contained">
          See More
        </Button>
      </a>
      {/*<IconButton aria-label="share">
        <ShareIcon />
</IconButton>*/}
    </CardActions>
  </Card>
</Grid>





<Grid item xs="12" md={6} lg={4}>
  <Card className={classes.Card}>
    <CardHeader  title="Dev" subheader="Under Construction" />
    <CardContent>
      <Typography variant="body2" color="textSecondary" component="p">
        making and testing electronics.
      </Typography>
    </CardContent>
    <CardActions disableSpacing>
      <a href="http://climatesens.com/" style={{textDecoration:"none"}} target="_blank">
        <Button className={classes.Button} variant="contained">
          See More
        </Button>
      </a>
      {/*<IconButton aria-label="share">
        <ShareIcon />
</IconButton>*/}
    </CardActions>
  </Card>
</Grid>


      </Grid>
    </div>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCategories);
