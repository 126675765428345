import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import { firestore } from 'firebase'

import  firebase  from 'firebase'


import {Card,CardHeader,CardMedia,CardContent,CardActions,IconButton,Collapse} from '@material-ui/core';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import mainStyle from "../../assets/mainStyle";

const useStyles = makeStyles((theme) => ({
    ... mainStyle,
  root: {
    margin: '0 40px',
    marginTop: '80px',
  },
  MaintenanceRoot:{
      textAlign: "center;",
      paddingTop: "20px;",
      marginBottom: "100px;",
  }
  

}));


function List({ServicesDetails,match}) { 
  const classes = useStyles();

  useEffect(() => {
        document.title = 'SPRIED'
  }, [])

  if(!ServicesDetails || ServicesDetails.id !== match.params.id)
  {
    return (
        <div className={classes.root}>
            Loading
        </div>)
  }
  
  document.title = 'SPRIED - ' + ServicesDetails.name

  return (
    <div className={classes.root}>
      <h2>{ServicesDetails.name}</h2> 
      <hr class="h2Line"/>
      <div component="main">
            
      <Container className={classes.MaintenanceRoot}>
                <h1><strong>Under Maintenance.</strong></h1>
            </Container>
      </div>
    </div>
  );
}







const mapStateToProps = (state, ownProps) => {

    
    var ServicesDetails = null;
    
    if(state.firestore.ordered.ServicesDetails && state.firestore.ordered.ServicesDetails[0])
        ServicesDetails = state.firestore.ordered.ServicesDetails[0];


  return {
    ServicesDetails: ServicesDetails
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props =>{
    return [
      { collection: 'Services', storeAs: 'ServicesDetails',doc:props.match.params.id}
    ]
})
)(List);