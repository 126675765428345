import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    MainContainer:{
        textAlign:"center",
        marginTop:"70px",

    },
    firebaseui:{
        height:"400px"
    },
    spriedImage:{
        width:"80%",
        maxWidth:"300px",
        textAlign: "center;"
    },
    title:{
        fontSize: "42px!important;"
    }
});

const Login = () => {
    const classes = useStyles();
    const firebase = getFirebase()
    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/',
        // We will display Google and Facebook as auth providers.
        //provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,      
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            firebase.auth.GithubAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ], 
        
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                var isNewUser = authResult.additionalUserInfo.isNewUser;
                window.location.href = "https://account.spried.com";
                return false;
            },
            uiShown: function() {
            }
        }
    };

    return (
        <Container className={classes.MainContainer}>

            <img  className={classes.spriedImage} src="/images/spried-black.png" />
            <h1 className={classes.title}>Login</h1>

            <Box id="firebaseui-auth-container" className={classes.firebaseui}>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
            </Box>
        </Container>
    )
    
}
export default Login
