import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import { firestore } from 'firebase'

import  firebase  from 'firebase'

import ProductCategories from '../modules/views/ProductCategories';
import ProductSmokingHero from '../modules/views/ProductSmokingHero';
import ProductHero from '../modules/views/ProductHero';
import ProductValues from '../modules/views/ProductValues';
import ProductHowItWorks from '../modules/views/ProductHowItWorks';
import ProductCTA from '../modules/views/ProductCTA';

import {Card,CardHeader,CardMedia,CardContent,CardActions,IconButton,Collapse} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 40px',
    marginTop: '80px',
  },
  ContactForm:{
    marginBottom:"40px"
  },
  ContactForm:{
    marginBottom:"40px"
  },
  TextField:{
    width:"100%",
    marginTop:"20px"
  },
  SendButton:{
    marginTop:"10px",
    background:"#415A77",
    color:"#E0E1DD",
    fontSize:"16px",
    padding:"10px 20px"
  },

  ProductsRoot: {
    padding:"40px 0"
  },
  header: {
    marginBottom: "30px",
  },
  Card:{
    /*border:"1px solid #888"*/
  },
  product: {
    padding: "10px",
    textAlign: "center;",
  },
  chipsBox: {
    width:"100%",
    textAlign: "center;",
    margin:"10px 0 ",
  },
  chip:{
    margin:"0 3px",
  },
  ButtonBox: {
    width:"100%",
    textAlign: "center;",
    margin:"10px 0 "
  },
  Button:{
    marginTop:"10px",
    background:"#415A77",
    color:"#E0E1DD",
    fontSize:"16px",
    padding:"10px 20px",
    textDecoration: "none",
    "&:hover":{
      /*color:"#415A77",
      background:"#E0E1DD",*/

    }
  },
  ButtonDetails:{
    marginTop:"10px",
    background:"#fff",
    color:"#415A77",
    fontSize:"16px",
    padding:"10px 20px",
    textDecoration: "none",
    boxShadow:"none",
    
    "&:hover":{
      /*color:"#415A77",
      background:"#E0E1DD",*/



    }
  },
  ButtonLink:{
    textDecoration: "none"
  }

}));


function Home({Services}) { 
  const classes = useStyles();

  
  useEffect(() => {
    document.title = 'SPRIED'
  }, [])

  
  return (
    
    <div className={classes.root}>
      <div className={classes.ProductsRoot} component="section">

      <Grid container spacing={3}>

        {Services && Services.map((product) => (
          <Grid item xs="12" md={12} lg={12}>
            <Card className={classes.Card}>
              <CardHeader  title={product.name}  subheader={product.construction ? "Under Construction" : null}/>
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {product.excerpt}
                </Typography>
              </CardContent>
              <CardActions disableSpacing>

                  <NavLink to={"/services/"+product.id} style={{textDecoration:"none"}}> 
                    <Button className={classes.ButtonDetails} variant="contained">
                    Details
                    </Button>
                  </NavLink>
                  
                  {product.url ? 
                  <a href={product.url} style={{textDecoration:"none"}} target="_blank"> 
                    <Button className={classes.Button} variant="contained">
                      Go to product
                    </Button>
                  </a> : null}
              </CardActions>
            </Card>
          </Grid>))
        }


      </Grid>

      </div>

    </div>
  );
}







const mapStateToProps = (state, ownProps) => {
  return {
    Services: state.firestore.ordered.Services
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props =>{
    return [
      { collection: 'Services', storeAs: 'Services'}
    ]
})
)(Home);



