import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
//import { Link, animateScroll as scroll } from "react-scroll";
import Link from '@material-ui/core/Link';
//import AppBar from '../components/AppBar';
import Toolbar, { styles as toolbarStyles } from '../components/Toolbar';
import { NavLink } from 'react-router-dom'

import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
//import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

const drawerWidth = 300;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  title:{
    paddingTop:" 64px!important;",
    paddingBottom: "64px!important;",
    width:"240px",
    textAlign:"center",
    fontSize:"30px",
    padding: "0 16px;",
    color:"#E0E1DD"
  },
  linkBox:{
    padding: "0 16px;",
    paddingTop: "34px"
  },
  link:{
    display: "block;",
    padding: "8px 16px;",
    textAlign: "left;",
    border: "none;",
    whiteSpace: "normal;",
    float: "none;",
    outline: "0;",
    color: "#E0E1DD",
    fontWeight:" bold;",
    fontSize:" 18px!important;",
    textDecoration: "none;",
    "&:hover":{
      color: "#1B263B;",
      backgroundColor: "#E0E1DD",
      textDecoration: "none;",
    }
  },
  NavLink:{
    textDecoration: "none;",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    color: "#E0E1DD",
    backgroundColor: "#1B263B;",
  },
  drawerPaper: {
    width: drawerWidth,
    color: "#E0E1DD",
    backgroundColor: "#1B263B;",
  },
  // necessary for content to be below app bar
  //toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    //padding: theme.spacing(0),
  },
});

function AppAppBar(props) {
  const { classes } = props;

  return (
    <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <Box className={classes.linkBox}>
          
          <a className={classes.NavLink} href="https://spried.com/">
            <img  className={classes.title} src="/images/spried-white.png" />
          </a>

          <a className={classes.NavLink} href="https://spried.com/"><Box className={classes.link} variant="contained">Main Site</Box></a>
          
          <hr/>
          <NavLink className={classes.NavLink} to="/"><Box className={classes.link} variant="contained">Home</Box></NavLink>
          <NavLink className={classes.NavLink} to="/services"><Box className={classes.link} variant="contained">Services</Box></NavLink>
          <NavLink className={classes.NavLink} to="/products"><Box className={classes.link} variant="contained">Products</Box></NavLink>
          <NavLink className={classes.NavLink} to="/orders"><Box className={classes.link} variant="contained">Orders</Box></NavLink>
          
          <hr/>

          <NavLink className={classes.NavLink} to="/profile"><Box className={classes.link} variant="contained">Profile</Box></NavLink>
          <NavLink className={classes.NavLink} to="/notifications"><Box className={classes.link} variant="contained">Notifications</Box></NavLink>
          <a className={classes.NavLink} href="/logout"><Box className={classes.link} variant="contained">Logout</Box></a>
        </Box>
      </Drawer>
  );
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
