import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';

import Logout from './views/auth/Logout';

import Home from './views/Home';
import Page404 from './views/Page404';
import Maintenance from './views/Maintenance';
import Profile from './views/Profile/Profile';
import Product_Details from './views/Product/Details';

import withRoot from './modules/withRoot';
import AppAppBar from './modules/views/AppAppBar';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    //backgroundColor: theme.palette.background.default,
    //padding: theme.spacing(3),
  },
}));

function App() {

  const classes = useStyles();

  return (
    <BrowserRouter>
    
      <div className={classes.root}>
        <AppAppBar />
        <main className={classes.content}>
            <Switch>
              <Route exact path='/logout' component={Logout}/>


              <Route exact path='/' component={Home} />
              <Route exact path='/services' component={Maintenance} />
              <Route exact path='/products' component={Maintenance} />
              <Route exact path='/orders' component={Maintenance} />
              <Route exact path='/profile' component={Profile} />
              <Route exact path='/notifications' component={Maintenance} />

              
            <Route component={Page404}  status={404}/>
            
            </Switch>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default App;
