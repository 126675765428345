import React, {Component,useState  } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import { withFirestore, isLoaded, isEmpty } from 'react-redux-firebase'
import { reduxFirestore, getFirestore } from 'redux-firestore'
import  firebase from 'firebase'
import { firestore } from 'firebase'


import { makeStyles } from "@material-ui/core/styles";

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField, 
    CircularProgress,
    FormControl,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  
  Typography
    
  } from '@material-ui/core';

import styles from "../../assets/mainStyle";
const useStyles = makeStyles({... styles,
  NotificationsLabel:{

  }
});
    
  
  const AccountProfileDetails = (props) => {
    const {user,firestore,Profile,Loaded} = props
    const classes = useStyles();
    const [values, setValues] = useState(null);

    if(Loaded && !values)
    {
      var ProfileData = {
        displayName: user.displayName,
        email: user.email,
        country: "",
        notifications:{
          device:2,
          groups:2,
          deviceActions:2,
          deviceNotifications:2,
          globalActions:2,
          changesSettings:2,
        }
      }

      if(Profile)
        ProfileData = {...ProfileData, ...Profile}

      setValues(ProfileData);
    }

    const handleChange = (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    };

    const handleNotificationsChange = (event) => {
      setValues({
        ...values,
        notifications:{
          ...values.notifications,
          [event.target.name]: event.target.value
        }
      });
    };

    const handleSubmit = (event) => { 
      event.preventDefault();
      console.log("Profile: handleSubmit")

      user.updateProfile({
        displayName: values.displayName,
      }).then(() => {
        console.log("Success")
      }).catch((error) => {
        console.log(error)
        alert("An error has occurred, please try again later")
      }); 
      /*
      user.updateEmail(values.email).then(() => {
        console.log("Success")
      }).catch((error) => {
        console.log(error)
        alert("An error has occurred, please try again later")
      });*/

      if(!Profile)
      {
        firestore.collection("Users").doc(user.uid).set({
          displayName: values.displayName,
          email: values.email,
          country: values.country,
          notifications: values.notifications,
        })
        .then(() => {
            console.log("Document successfully written!");
        })
        .catch((error) => {
            console.error("Error writing document: ", error);
        });
      }
      else
      {
        firestore.collection("Users").doc(user.uid).update({
          displayName: values.displayName,
          email: values.email,
          country: values.country,
          notifications: values.notifications,
        })
        .then(() => {
            console.log("Document successfully written!");
        })
        .catch((error) => {
            console.error("Error writing document: ", error);
        });
      }

    };


    if(!values || !Loaded)
    return(
      <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '90vh' }}>
          <Grid item xs={3}   justify="center">
              <CircularProgress className={classes.color}/>
          </Grid>  
          <Grid item xs={3} justify="center">
                Loading
          </Grid>  
      </Grid> 
    )


    return (
        <main>
      <form onSubmit={handleSubmit} action="post"
        autoComplete="off"
        noValidate
      >
        <Card>
          <CardHeader
            subheader="The information can be edited"
            title="Profile"
          />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  helperText="Please specify the name"
                  label="Display Name"
                  name="displayName"
                  onChange={handleChange}
                  required
                  value={values.displayName}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  required
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Country"
                  name="country"
                  onChange={handleChange}
                  required
                  value={values.country}
                  variant="outlined"
                />
              </Grid>
              

              <Grid
                item
                md={12}
                xs={12}
              >

              
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >
              Save details
            </Button>
            </Grid>
            </Grid>
          </CardContent>
        </Card>
      <Divider />
 <Card>
   <CardHeader
     subheader="Manage the notifications"
     title="Notifications"
   />
   <Divider />
   <CardContent>
     <Grid
       container
       spacing={6}
       wrap="wrap"
     >


<Grid item md={3} sm={6}  xs={12} className={classes.NotificationsLabel}>
          <FormControl fullWidth className={classes.formControl}>
            Devices: 
          </FormControl>
        </Grid>
        <Grid item md={3} sm={6} xs={12} >
          <FormControl fullWidth className={classes.formControl}>
            <Select value={values.notifications.device} onChange={handleNotificationsChange} name="device" className={classes.selectEmpty} inputProps={{ 'aria-label': 'Without label' }}>
              <MenuItem value={2}>All</MenuItem>
              <MenuItem value={1}>Selected</MenuItem>
              <MenuItem value={0}>None</MenuItem>
            </Select>
          </FormControl>
        </Grid>

<Grid item md={3} sm={6}  xs={12} className={classes.NotificationsLabel}>
  <FormControl fullWidth className={classes.formControl}>
    Groups: 
  </FormControl>
</Grid>
<Grid item md={3} sm={6} xs={12} >
  <FormControl fullWidth className={classes.formControl}>
    <Select value={values.notifications.groups} onChange={handleNotificationsChange} name="groups" className={classes.selectEmpty} inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={2}>All</MenuItem>
      <MenuItem value={1}>Selected</MenuItem>
      <MenuItem value={0}>None</MenuItem>
    </Select>
  </FormControl>
</Grid>


<Grid item md={3} sm={6}  xs={12} className={classes.NotificationsLabel}>
  <FormControl fullWidth className={classes.formControl}>
    Device Actions: 
  </FormControl>
</Grid>
<Grid item md={3} sm={6} xs={12} >
  <FormControl fullWidth className={classes.formControl}>
    <Select value={values.notifications.deviceActions} onChange={handleNotificationsChange} name="deviceActions" className={classes.selectEmpty} inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={2}>All</MenuItem>
      <MenuItem value={1}>Selected</MenuItem>
      <MenuItem value={0}>None</MenuItem>
    </Select>
  </FormControl>
</Grid>


<Grid item md={3} sm={6}  xs={12} className={classes.NotificationsLabel}>
  <FormControl fullWidth className={classes.formControl}>
    Global Actions: 
  </FormControl>
</Grid>
<Grid item md={3} sm={6} xs={12} >
  <FormControl fullWidth className={classes.formControl}>
    <Select value={values.notifications.globalActions} onChange={handleNotificationsChange} name="globalActions" className={classes.selectEmpty} inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={2}>All</MenuItem>
      <MenuItem value={1}>Selected</MenuItem>
      <MenuItem value={0}>None</MenuItem>
    </Select>
  </FormControl>
</Grid>


<Grid item md={3} sm={6}  xs={12} className={classes.NotificationsLabel}>
  <FormControl fullWidth className={classes.formControl}>
    Device Notifications: 
  </FormControl>
</Grid>
<Grid item md={3} sm={6} xs={12} >
  <FormControl fullWidth className={classes.formControl}>
    <Select value={values.notifications.deviceNotifications} onChange={handleNotificationsChange} name="deviceNotifications" className={classes.selectEmpty} inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={2}>All</MenuItem>
      <MenuItem value={0}>None</MenuItem>
    </Select>
  </FormControl>
</Grid>
       


<Grid item md={3} sm={6}  xs={12} className={classes.NotificationsLabel}>
  <FormControl fullWidth className={classes.formControl}>
    Changes Settings: 
  </FormControl>
</Grid>
<Grid item md={3} sm={6} xs={12} >
  <FormControl fullWidth className={classes.formControl}>
    <Select value={values.notifications.changesSettings} onChange={handleNotificationsChange} name="changesSettings" className={classes.selectEmpty} inputProps={{ 'aria-label': 'Without label' }}>
      <MenuItem value={2}>All</MenuItem>
      <MenuItem value={0}>None</MenuItem>
    </Select>
  </FormControl>
</Grid>
       












       <Grid
                item
                md={12}
                xs={12}
              >

              
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              Save details
            </Button>
            </Grid>
     </Grid>
   </CardContent>
 </Card>
 {/*}
<Divider />

      <Card>
        <CardHeader
          subheader="Update password"
          title="Password"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirm password"
            margin="normal"
            name="confirm"
            onChange={handleChange}
            type="password"
            value={values.confirm}
            variant="outlined"
          />

          
       
       <Box           >

              
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              Save details
            </Button>
            </Box>
        </CardContent>
      </Card>
           {*/}
    </form>
      </main>
    );
  };

  const mapStateToProps = (state) => {

    return {
        user:firebase.auth().currentUser,
        Loaded:state.firestore.ordered.Profile ? true : false,
        Profile: state.firestore.ordered.Profile && state.firestore.ordered.Profile[0] ? state.firestore.ordered.Profile[0] : null
    }
}


export default compose(
    connect(mapStateToProps),
    firestoreConnect(props =>{
        return[
            { collection: 'Users', storeAs: 'Profile', doc:props.user.uid},
        ]
    })
) (AccountProfileDetails);